import React, { useState } from "react";
import emailjs from "emailjs-com";
import { useTranslation } from "react-i18next";

const Result = () => {
  const {t} = useTranslation();
  return (
    <p className="success-message">
      {t('contact.success')}
    </p>
  );
};
function ContactForm({ props }) {
  const [result, showresult] = useState(false);
  const {t} = useTranslation();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_c4nz70v",
        "template_xgac9ma",
        e.target,
        "q_2CK2astZiB1mHt9"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
    showresult(true);
  };

  setTimeout(() => {
    showresult(false);
  }, 5000);

  return (
    <form action="" onSubmit={sendEmail}>
      <div className="rn-form-group">
        <input
          type="text"
          name="fullname"
          placeholder={t('contact.name')}
          required
        />
      </div>

      <div className="rn-form-group">
        <input type="email" name="email" placeholder={t("contact.email")} required />
      </div>

      <div className="rn-form-group">
        <input type="text" name="phone" placeholder={t('contact.phone')} required />
      </div>

      <div className="rn-form-group">
        <input type="text" name="subject" placeholder={t('contact.subject')} required />
      </div>

      <div className="rn-form-group">
        <textarea name="message" placeholder={t('contact.message')} required></textarea>
      </div>

      <div className="rn-form-group">
        <button
          className="rn-button-style--2 btn-solid"
          type="submit"
          value="submit"
          name="submit"
          id="mc-embedded-subscribe"
        >
          {t('contact.send')}
        </button>
      </div>

      <div className="rn-form-group">{result ? <Result /> : null}</div>
    </form>
  );
}
export default ContactForm;
