import React, { Component } from "react";
import { useTranslation } from "react-i18next";
import { FiCast, FiLayers, FiUsers, FiMonitor } from "react-icons/fi";

const ServiceList = [
  {
    icon: <FiCast />,
    title: "Estrategia de Negocios",
    description:
      "Ayudamos a las empresas a definir un camino claro hacia el éxito con planes estratégicos que integran todos los aspectos del negocio.",
  },
  {
    icon: <FiLayers />,
    title: "Desarrollo de Sitios Web",
    description:
      "Creamos sitios web atractivos y funcionales que cumplen con las necesidades de nuestros clientes y sus visitantes.",
  },
  {
    icon: <FiUsers />,
    title: "Marketing y Reportes",
    description:
      "Ofrecemos soluciones de marketing digital y generamos reportes detallados para ayudar a las empresas a alcanzar sus objetivos.",
  },
  {
    icon: <FiMonitor />,
    title: "Desarrollo de Aplicaciones Móviles",
    description:
      "Desarrollamos aplicaciones móviles intuitivas y atractivas para iOS y Android que ofrecen una excelente experiencia de usuario.",
  },
];

class ServicesSlide extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-lg-4 col-12">
            <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
              <h2 className="title">{this.props.t("header.services")}</h2>
              <h4>{this.props.t("services.subtitle")}</h4>
              <p dangerouslySetInnerHTML={{
                          __html: this.props.t(`services.services`),
                        }}/>
              
              <div className="service-btn">
                {/* <a className="btn-transparent rn-btn-dark" href="/service"><span className="text">Request Custom Service</span></a> */}
              </div>
            </div>
          </div>
          <div className="col-lg-8 col-12 mt_md--50">
            <div className="row service-one-wrapper">
              {ServiceList.map((val, i) => (
                <div
                  className="col-lg-6 col-md-6 col-sm-6 col-12"
                  style={{ cursor: "pointer" }}
                  key={i}
                >
                  <div className="service service__style--2">
                    <div className="icon">{val.icon}</div>
                    <div className="content">
                      <h3 className="title">
                        {this.props.t(`services.title${i + 1}`)}
                      </h3>

                      <p
                        className="description"
                        dangerouslySetInnerHTML={{
                          __html: this.props.t(`services.description${i + 1}`),
                        }}

                      ></p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const ServiceTwo = () => {
  const { t } = useTranslation();
  return <ServicesSlide t={t} />;
};
export default ServiceTwo;
