import React, { Component } from "react";

import aboutImg from "../../../assets/images/about/about.jpeg";
import { useTranslation } from "react-i18next";

class AboutPage extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="about-wrapper">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-5 col-md-12">
                <div className="thumbnail">
                  <img className="w-100" src={aboutImg} alt="About Images" />
                </div>
              </div>

              <div className="col-lg-7 col-md-12">
                <div className="about-inner inner">
                  <div className="section-title" style={{marginTop:"2rem"}}>
                    <h2 className="title">{this.props.t('header.about')}</h2>
                    <h4> {this.props.t('about.subtitle')}</h4>
                    <p className="description" dangerouslySetInnerHTML={{ __html: this.props.t('about.text') }}></p>
                  </div>
                  {/* <div className="row mt--30 mt_sm--10">
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <h3 className="title">Who we are</h3>
                        <p>
                          There are many vtions of passages of Lorem Ipsum
                          available, but the majority have suffered.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <h3 className="title">Who we are</h3>
                        <p>
                          There are many vtions of passages of Lorem Ipsum
                          available, but the majority have suffered.
                        </p>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
const About = () => {
  const { t } = useTranslation();
  return <AboutPage t={t} />;
};
export default About;
