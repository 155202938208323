import React, { Component, useEffect, useState  } from "react";
import {
  FaTwitter,
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn,
  FaWhatsapp,
  FaMapMarkerAlt,
} from "react-icons/fa";
import { Link } from "react-scroll";
import { useTranslation } from "react-i18next";
const SocialShare = [
  {
    Social: <FaFacebookF />,
    link: "https://www.facebook.com/profile.php?id=61559331371121",
  },
  {
    Social: <FaLinkedinIn />,
    link: "https://www.linkedin.com/in/digital-pulse-179209307/",
  },
  {
    Social: <FaInstagram />,
    link: "https://www.instagram.com/digitalpulse.es/",
  },
  // { Social: <FaTwitter />, link: "https://twitter.com/" },
  { Social: <FaWhatsapp />, link: "https://wa.me/+34603775281" },
];

class FooterComponent extends Component {
  render() {
    const whatsappButtonClass = this.props.scrolled
      ? 'whatsapp-button '
      : 'whatsapp-button whatsapp0';

    return (
      <React.Fragment>
        <footer className="footer-area">
          <a
            href="https://wa.me/+34603775281"
            className={whatsappButtonClass}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
              alt="WhatsApp"
              width="50"
              height="50"
            />
          </a>
          <div className="footer-wrapper">
            <div className="row align-items-end row--0">
              <div className="col-lg-6">
                <div className="footer-left">
                  <div className="inner">
                    <span> {this.props.t("footer.subtitle")}</span>
                    <h2>{this.props.t("footer.subtitle2")}</h2>

                    <Link
                      className="menu-item rn-button-style--2"
                      activeClass="active"
                      to="contact" // El id de la sección a la que quieres desplazarte
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={1000}
                    >
                      {this.props.t("header.contact")}
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="footer-right" data-black-overlay="6">
                  <div className="row">
                    {/* Start Single Widget  */}
                    <div className="col-lg-6 col-sm-6 col-12">
                      <div className="footer-link">
                        <h4> {this.props.t("footer.access")}</h4>
                        <ul className="ft-link">
                          <li>
                            <Link
                              className="menu-item "
                              activeClass="active"
                              to="services"
                              spy={true}
                              smooth={true}
                              offset={-70}
                              duration={1000}
                            >
                              {this.props.t("header.services")}
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="menu-item "
                              activeClass="active"
                              to="about" // El id de la sección a la que quieres desplazarte
                              spy={true}
                              smooth={true}
                              offset={-70}
                              duration={1000}
                            >
                              {this.props.t("header.about")}
                            </Link>
                          </li>
                          {/* <li>
                            <Link
                              className="menu-item "
                              activeClass="active"
                              to="contact" // El id de la sección a la que quieres desplazarte
                              spy={true}
                              smooth={true}
                              offset={-70}
                              duration={1000}
                            >
                              Whatsapp{" "}
                            </Link>
                          </li> */}
                        </ul>
                      </div>
                    </div>
                    {/* End Single Widget  */}
                    {/* Start Single Widget  */}
                    <div className="col-lg-6 col-sm-6 col-12 mt_mobile--30">
                      <div className="footer-link">
                        <h4>{this.props.t("footer.networks")}</h4>
                        <ul className="ft-link">
                          <li>
                            <a
                              href="mailto:agencia@digitalpulse.es"
                            >
                              agencia@digitalpulse.es
                            </a>
                          </li>
                          <li className="location">
                            <FaMapMarkerAlt /> Mareseme - Barcelona - España
                          </li>
                          <li>
                            <a href="tel:+34603775281">+34 603 775 281</a>
                          </li>
                        </ul>

                        <div className="social-share-inner">
                          <ul className="social-share social-style--2 d-flex justify-content-start liststyle mt--15">
                            {SocialShare.map((val, i) => (
                              <li key={i}>
                                <a target="_blank" href={`${val.link}`}>
                                  {val.Social}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* End Single Widget  */}

                    <div className="col-lg-12">
                      <div className="copyright-text">
                        <p>
                          Copyright © 2024 Digital Pulse Agency. All Rights
                          Reserved.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}

const Footer = (props) => {
  const { t } = useTranslation();
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScrolled(isScrolled);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return <FooterComponent t={t} scrolled={scrolled} {...props} />;
};
export default Footer;
