import React, { Component } from "react";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

import SliderOne from "../component/slider/SliderOne";
import ServiceTwo from "../elements/service/ServiceTwo";

import About from "../component/HomeLayout/homeOne/About";
import Portfolio from "../component/HomeLayout/homeOne/Portfolio";


import Helmet from "../component/common/Helmet";

import ContactTwo from "../elements/contact/ContactTwo";

class MainDemo extends Component {
  render() {

    return (
      <div className="active-dark">
        <Helmet pageTitle="Digital Pulse Agency" />
        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Slider Area   */}
        <div id="home" className="slider-wrapper">
          <SliderOne />
        </div>
        {/* End Slider Area   */}

        {/* Start About Area */}
        <div
          id="about"
          className="about-area about-position-top pb--120 bg_color--1"
        >
          <About />
        </div>
        {/* End About Area */}

        {/* Start Service Area  */}
        <div
          id="servicios"
          className="service-area ptb--80  bg_image bg_image--3"
        >
          <div className="container">
            <ServiceTwo />
          </div>
        </div>
        {/* End Service Area  */}

        {/* Start Portfolio Area */}
        <div id="pages" className="portfolio-area ptb--120 bg_color--1">
          <div className="portfolio-sacousel-inner mb--55">
            <Portfolio />
          </div>
        </div>
        {/* End Portfolio Area */}

        {/* Start Contact Area */}
        <div id="contact" className="rn-contact-area ptb--120 bg_color--1">
          <ContactTwo />
        </div>
        
        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={150}>
            <FiChevronUp />
            
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </div>
    );
  }
}
export default MainDemo;
