import React, { Component } from "react";
import ContactForm from "./ContactForm";

import about from "../../assets/images/contact/contact.jpeg";
import { useTranslation } from "react-i18next";

class ContactBox extends Component {
  render() {
    return (
      <div className="contact-form--1">
        <div className="container">
          <div className="row row--35 align-items-start">
            <div className="col-lg-6 order-2 order-lg-1">
              <div className="section-title text-left mb--50">
                <h2 className="title">{this.props.t('contact.title')}</h2>
                <p className="description">
                {this.props.t('contact.description')}
                </p>
              </div>
              <div className="form-wrapper">
                <ContactForm />
              </div>
            </div>
            <div className="col-lg-6 order-1 order-lg-2">
              <div className="thumbnail mb_md--30 mb_sm--30">
                <img
                  style={{
                    filter: "hue-rotate(-10deg) saturate(2)",
                  }}
                  max-width="700px"
                  max-height="745px"
                  src={about}
                  alt="trydo"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const ContactTwo = () => {
  const {t} = useTranslation();
  return <ContactBox t={t} />;
}
export default ContactTwo;
