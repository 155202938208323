import React, { Component } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
const languageMapping = {
  en: "en_UK",
  es: "es_ES",
  pt: "pt_BR",
};
class MetaTags extends Component {
  
  render() {
    const ogLocale = languageMapping[this.props.language] || this.props.language;

    return (
      <React.Fragment>
        <Helmet
          htmlAttributes={{
            lang: i18n.language,
          }}
        >
          <title>
            {this.props.t(`metaTitle`)}
          </title>
          <link rel="canonical" href="https://digitalpulse.es/" />		
          <meta charset="UTF-8" />
          <meta name="description" content={this.props.t(`metaDescription`)} />
          <meta name="keywords" content={this.props.t(`metaKeywords`)} />
          <link rel="apple-touch-icon" href="/apple-touch-icon-iphone-60x60.png"/>
          <link rel="apple-touch-icon" sizes="60x60" href="/apple-touch-icon-ipad-76x76.png"/>
          <link rel="apple-touch-icon" sizes="114x114" href="/apple-touch-icon-iphone-retina-120x120.png"/>
          <link rel="apple-touch-icon" sizes="144x144" href="/apple-touch-icon-ipad-retina-152x152.png"/>								
          <meta property="og:title" content={this.props.t(`metaTitle`)} />
          <meta
            property="og:description"
            content={this.props.t(`metaDescription`)}
          />
          <meta property="og:locale" content={ogLocale} />

          <meta property="og:image" content="./public/digitalpulse.ico" />
          <meta property="og:url" content="www.digitalpulse.es" />
          <meta content="IE=edge" http-equiv="X-UA-Compatible" />		
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="robots"
            content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
          />
        </Helmet>
      </React.Fragment>
    );
  }
}
const PageHelmet = () => {
  const { t } = useTranslation();
  return <MetaTags t={t} language={i18n.language} />;
};
export default PageHelmet;
