import React, { useContext, useEffect } from "react";
import Select, { components } from "react-select";
import { LanguageContext } from "../../Context/LangContext";
import ukflag from "../../assets/images/icons/uk-flag.png";
import esflag from "../../assets/images/icons/es-flag.png";
import ptflag from "../../assets/images/icons/brazil-flag.png";
import {useLocation} from 'react-router-dom';
function LanguageSelector() {
  const { language, changeLanguage } = useContext(LanguageContext);
  const location = useLocation();

  const languageOptions = [
    { value: "es", label: "Español", icon: esflag },
    { value: "en", label: "English", icon: ukflag },
    { value: "pt", label: "Português", icon: ptflag },
  ];
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const lang = params.get('lang');
    changeLanguageSafe(lang);
  }, [location]);
  const selectedOption =
    languageOptions.find((option) => option.value === language) ||
    languageOptions.find((option) => option.value === "es");
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img
          src={selectedOption ? selectedOption.icon : undefined}
          alt="flag"
          style={{
            width: "20px",
            height: "20px",
            borderRadius: "50%",
            objectFit: "cover",
          }}
        />
      </components.DropdownIndicator>
    );
  };

  const Option = (props) => {
    return (
      <components.Option {...props}>
        <img
          src={props.data.icon}
          alt={props.data.label}
          style={{
            width: "20px",
            height: "20px",
            borderRadius: "50%",
            objectFit: "cover",
          }}
        />
      </components.Option>
    );
  };
  const changeLanguageSafe = (lang) => {
    if (lang && languageOptions.some(option => option.value === lang)) {
      changeLanguage(lang);
    } else {
      changeLanguage('es');
    }
  };
  const customStyles = {
    option: (provided) => ({
      ...provided,
      //   padding: 15,
      padding: 5,
      justifyContent: "center",
      margin: "auto",
      display: "flex",
      backgroundColor: "black",
      gap: "2rem",
      //   backgroundColor: state.isActived ? 'black' : 'black',
      marginColor: "black",
      cursor: "pointer",
      "&:active": {
        backgroundColor: "black",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      display: "none",
    }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent",
      borderColor: "transparent",
      boxShadow: "none",
      cursor: "pointer",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "black",
      //   color: "white",
    }),
    // valueContainer: (provided) => ({
    //     ...provided,
    //     display: 'none',
    //   }),
  };

  return (
    <Select
      components={{ DropdownIndicator, Option, IndicatorSeparator: () => null }}
      value={languageOptions.find((option) => option.value === language)}
      onChange={(option) => changeLanguageSafe(option.value)}
      options={languageOptions}
      styles={customStyles}
      isSearchable={false}
      //   isClearable={false}
    />
  );
}

export default LanguageSelector;
