import React, { Component } from "react";
import Slider from "react-slick";
import { portfolioSlick, portfolioSlick2 } from "../../../page-demo/script";
import { Link } from "react-router-dom";
import lppImg from "../../../assets/images/portfolio/lpp.png";
import puzzlesImg from "../../../assets/images/portfolio/puzzles.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from "react-i18next";
const PortfolioList = [
  {
    image: "lppImage",
    category: "Development & Design",
    title: "Estudio Jurídico Lopez - Perez Paredes & Asoc.",
    url: "https://lopeza93.github.io/ejlpp/index.html",
  },
  {
    image: "puzzlesImage",
    category: "Development & Design",
    title: " Getting tickets to the big show",
    url: "https://puzzles-65bfd.web.app/",
  },
  {
    image: "BzImage",
    category: "Development & Design",
    title: "Escribanía Bustamante Zambon",
    url: "https://escribaniabustamantezambon.com.ar/",
  },
  {
    image: "UniFluImage",
    category: "Development & Design",
    title: "Escola de Engenharia de Petrópolis UFF – Graduação e Pós-Graduação em Engenharia de Produção na cidade de Petrópolis no Estado do Rio de Janeiro – UFF",
    url:"http://www.pep.uff.br/"
  },
  {
    image: "LCCImage",
    category: "Development & Design",
    title:
      " Laboratório de Cinética e Catálise - LCC/UERJ Zeólitas Biomassa Espectroscopia ",
    url: "https://lcc-uerj.com.br/",
  },
  {
    image: "greoImage",
    category: "Development & Design",
    title: " Grupo de Reologia PUC-Rio",
    url:"https://greo.mec.puc-rio.br/"
  },
];

class PortfolioSlide extends Component {
  render() {
    let title = "Algunos de nuestros trabajos",
      description =
        "Aquí puedes ver algunos de los proyectos en los que hemos trabajado. Cada uno de ellos muestra nuestra dedicación y habilidad para crear soluciones web y móviles efectivas.";
    return (
      <React.Fragment>
        <div className="portfolio-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="section-title">
                  <h2>{this.props.t("portfolio.title")}</h2>
                  <p>{this.props.t("portfolio.description")}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="portfolio-slick-activation mt--70 mt_sm--40">
            <Slider {...portfolioSlick2}>
              {PortfolioList.map((value, index) => (
                <div className="portfolio" key={index}>
                  <div className="thumbnail-inner">
                    <div className={`thumbnail ${value.image}`}></div>
                    <div className={`bg-blr-image ${value.image}`}></div>
                  </div>
                  <div className="content">
                    <div className="inner">
                      <p>{value.category}</p>
                      <h4 className="title">{value.title}</h4>
                      <div className="portfolio-button">
                        <a className="rn-btn" href={value.url} target="_blank">
                          Ver mas
                        </a>
                      </div>
                    </div>
                  </div>
                  <a
                    className="link-overlay"
                    href={value.url}
                    target="_blank"
                  ></a>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const Portfolio = () => {
  const { t } = useTranslation();
  return <PortfolioSlide t={t} />;
};
export default Portfolio;
